type LimitedItem = {
  limit: number,
  value: string
}

const limitedItemKey = 'storageUtil_LimitedItem';

//期限付き
export const setLimitedItem = (key:string, value:string, limitMin:number) => {
  const jsonstring = localStorage.getItem(limitedItemKey);
  let json = {};
  if(jsonstring) {
    json = JSON.parse(jsonstring);
  }
  const limit = Date.now() + limitMin * 60 * 1000;
  const item:LimitedItem = {limit:limit, value:value};
  json[key] = item;
  localStorage.setItem(limitedItemKey, JSON.stringify(json));
}
export const getLimitedItem = (key):string => {
  deleteOverItem();
  const jsonstring = localStorage.getItem(limitedItemKey);
  let json = {};
  if(jsonstring) {
    json = JSON.parse(jsonstring);
  }
  const item:LimitedItem = json[key];
  return item ? item.value : null;
}
export const removeLimitedItem = (key) => {
  const jsonstring = localStorage.getItem(limitedItemKey);
  let json = {};
  if(jsonstring) {
    json = JSON.parse(jsonstring);
  }
  delete json[key];
  localStorage.setItem(limitedItemKey, JSON.stringify(json));
}
const deleteOverItem = () => {
  const jsonstring = localStorage.getItem(limitedItemKey);
  if(!jsonstring) {
    return;
  }
  let change = false;
  const json = JSON.parse(jsonstring);
  const now = Date.now();
  Object.keys(json).forEach(key => {
    const item:LimitedItem = json[key];
    if(now > item.limit) {
      delete json[key];
      change = true;
    }
  });
  if(change) {
    localStorage.setItem(limitedItemKey, JSON.stringify(json));
  }
}