import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { MainPageColRowModel, mainPageColRowModelDefault } from "@/components/mainpage/MainPageTableModel";
import { originKbnList, optionAll } from "@/store/mainpage/mainPageCommon";
import { OptionTerm } from "@/components/custom/TermOptionSelecter";
import moment from "moment";

//強制表示列
export const mustVisibleColumnsKey: string[] = [
  "no",
  'origin',
  'keppin',
];
//デフォルト表示列
export const defaultVisibleColumnsKey: string[] = [
  'centerNM',
  'group1NM',
  'makerNM',
  'itemNM',
  'janCD',
  'itemCD',
  'syukkaDate',
  'nouhinDate',
  'keppinBL',
  'tanaban',
  'origin',
  'keppin',
  'biko',
  'responsibility',
];

export const optionsNameList = [
  'optionBumons',
  'optionCenters',
  'optionCenterCode',

  'optionMakers',
  'optionMakerCode',
  'optionItemCode',

  'optionGroup1s',
  'optionTokuisaki1s',
  'optionTokuisaki2s',
  'optionTorihikisakiCode',
  'optionTokuisaki1Code',

  'optionSlipNo',
  'optionEosFlg',
  'optionSyukkaTerm',
  'optionNouhinTerm',

  'optionTanaban',

  'optionInputStatus',

  'optionOrigin',
  'optionOrigins',
  'optionReasons',
];
export type MainPageOptions = {
  optionBumons?: CodeName[],    //部門
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,    //倉庫CD

  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,     //メーカーCD
  optionItemCode?: string,      //商品CD

  optionGroup1s?: CodeName[],       //企業グループ1
  optionTokuisaki1s?: CodeName[],   //得意先本部
  optionTokuisaki2s?: CodeName[],   //得意先支店
  optionTorihikisakiCode?: string,      //取引先CD
  optionTokuisaki1Code?: string,      //得意先本部CD

  optionSlipNo?: string,                  //伝票番号
  optionEosFlg?: '' | '1' | '0' | string, //EOSフラグ   1:EOS  0:手打ち
  optionSyukkaTerm?: OptionTerm,   //出荷日
  optionNouhinTerm?: OptionTerm,   //納品日

  optionTanaban?: string,                   //棚番

  optionInputStatus?: '' | '1' | '3' | '4' | string,   //入力状況 1:未入力 3:入力済 4:要確認

  optionOrigins?: CodeName[],  //発生個所
  optionReasons?: CodeName[],  //欠品理由
};

export const displaySettingsNameList = [
  'checkedColKey',
];

export type DisplaySettings = {
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type MainPageSaveState = {
  //検索条件
  optionBumons: CodeName[],     //部門
  optionCenters: CodeName[],    //倉庫
  optionCenterCode: string,     //倉庫CD

  optionMakers: CodeName[],     //メーカー
  optionMakerCode: string,      //メーカーCD
  optionItemCode: string,       //商品CD

  optionGroup1s: CodeName[],      //企業グループ1
  optionTokuisaki1s: CodeName[],  //得意先本部
  optionTokuisaki2s: CodeName[],  //得意先支店
  optionTorihikisakiCode: string,      //取引先CD
  optionTokuisaki1Code: string,      //得意先本部CD

  optionSlipNo: string,                   //伝票番号
  optionEosFlg: '' | '1' | '0' | string,  //EOSフラグ   1:EOS  0:手打ち
  optionSyukkaTerm: OptionTerm | null,    //出荷日
  optionNouhinTerm: OptionTerm | null,    //納品日

  optionTanaban: string,                   //棚番

  optionInputStatus: '' | '1' | '3' | '4' | string,   //入力状況 1:未入力 3:入力済 4:要確認

  optionOrigins: CodeName[] | null,  //発生個所
  optionReasons: CodeName[] | null,  //欠品理由

  favoriteOptions: { title: CodeName, params: MainPageOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  showSubTotal: 'なし' | 'あり' | 'のみ',        //商品集計を表示

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  colWidthsModified: {},

  sort: { key: string, asc: boolean },
};

export const initialMainPageState: MainPageSaveState = {
  optionBumons: [],   //部門
  optionCenters: [],   //倉庫
  optionCenterCode: '',            //倉庫CD

  optionMakers: [],    //メーカー
  optionMakerCode: '',            //メーカーCD
  optionItemCode: '',            //商品CD

  optionGroup1s: [],  //企業グループ1
  optionTokuisaki1s: [],  //得意先本部
  optionTokuisaki2s: [],  //得意先支店
  optionTorihikisakiCode: '',      //取引先CD
  optionTokuisaki1Code: '',      //得意先本部CD

  optionSlipNo: '',               //伝票番号
  optionEosFlg: optionAll.code,   //EOSフラグ
  optionSyukkaTerm: {
    termFrom: moment().startOf('day').toDate(),
    termTo: moment().startOf('day').toDate(),
  },                              //出荷日
  optionNouhinTerm: null,         //納品日

  optionTanaban: '',                   //棚番

  optionInputStatus: '1',   //入力状況 1:未入力

  optionOrigins: [],   //発生個所
  optionReasons: [],          //欠品理由

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  showSubTotal: 'のみ',        //商品集計を表示

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  colWidthsModified: {},

  sort: {
    key: "itemCD",
    asc: true,
  },
};

//Page Slice
export type MainPageSaveReducer = {
  initOnDidMount: (state: MainPageSaveState) => void,
  resetOnWillUnmount: (state: MainPageSaveState) => void,
  clearOption: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionBumons: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionMakers: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionGroup1s: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionTokuisaki1s: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionTokuisaki2s: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionTorihikisakiCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionTokuisaki1Code: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionSlipNo: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionEosFlg: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionSyukkaTerm: (state: MainPageSaveState, action: PayloadAction<OptionTerm>) => void,
  setOptionNouhinTerm: (state: MainPageSaveState, action: PayloadAction<OptionTerm>) => void,
  setOptionTanaban: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionInputStatus: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionOrigins: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionReasons: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setFavoriteOptions: (state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) => void,
  setFavoriteDisplaySettings: (state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setShowSubTotal: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  columnWidthChange: (state: MainPageSaveState, action: PayloadAction<{ colKey: string, width: number }>) => void,
  hideColumnKeysChange: (state: MainPageSaveState, action: PayloadAction<string[]>) => void,
  setSort: (state: MainPageSaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) => void,
}

const createReducerContent = (name: string, colRowModel: MainPageColRowModel, initialState: MainPageSaveState): MainPageSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: MainPageSaveState) {

      if (state.optionBumons === undefined) {
        state.optionBumons = initialMainPageState.optionBumons;
      }
      if (state.optionCenters === undefined) {
        state.optionCenters = initialMainPageState.optionCenters;
      }
      if (state.optionCenterCode === undefined) {
        state.optionCenterCode = initialMainPageState.optionCenterCode;
      }
      if (state.optionMakers === undefined) {
        state.optionMakers = initialMainPageState.optionMakers;
      }
      if (state.optionMakerCode === undefined) {
        state.optionMakerCode = initialMainPageState.optionMakerCode;
      }
      if (state.optionItemCode === undefined) {
        state.optionItemCode = initialMainPageState.optionItemCode;
      }
      if (state.optionGroup1s === undefined) {
        state.optionGroup1s = initialMainPageState.optionGroup1s;
      }
      if (state.optionTokuisaki1s === undefined) {
        state.optionTokuisaki1s = initialMainPageState.optionTokuisaki1s;
      }
      if (state.optionTokuisaki2s === undefined) {
        state.optionTokuisaki2s = initialMainPageState.optionTokuisaki2s;
      }
      if (state.optionTorihikisakiCode === undefined) {
        state.optionTorihikisakiCode = initialMainPageState.optionTorihikisakiCode;
      }
      if (state.optionTokuisaki1Code === undefined) {
        state.optionTokuisaki1Code = initialMainPageState.optionTokuisaki1Code;
      }
      if (state.optionSlipNo === undefined) {
        state.optionSlipNo = initialMainPageState.optionSlipNo;
      }
      if (state.optionEosFlg === undefined) {
        state.optionEosFlg = initialMainPageState.optionEosFlg;
      }
      if (state.optionSyukkaTerm === undefined) {
        state.optionSyukkaTerm = initialMainPageState.optionSyukkaTerm;
      }
      else {
        if (state.optionSyukkaTerm?.termFrom && typeof state.optionSyukkaTerm.termFrom === 'string') {
          state.optionSyukkaTerm.termFrom = new Date(state.optionSyukkaTerm.termFrom);
        }
        if (state.optionSyukkaTerm?.termTo && typeof state.optionSyukkaTerm.termTo === 'string') {
          state.optionSyukkaTerm.termTo = new Date(state.optionSyukkaTerm.termTo);
        }
      }
      if (state.optionNouhinTerm === undefined) {
        state.optionNouhinTerm = initialMainPageState.optionNouhinTerm;
      }
      else {
        if (state.optionNouhinTerm?.termFrom && typeof state.optionNouhinTerm.termFrom === 'string') {
          state.optionNouhinTerm.termFrom = new Date(state.optionNouhinTerm.termFrom);
        }
        if (state.optionNouhinTerm?.termTo && typeof state.optionNouhinTerm.termTo === 'string') {
          state.optionNouhinTerm.termTo = new Date(state.optionNouhinTerm.termTo);
        }
      }
      if (state.optionTanaban === undefined) {
        state.optionTanaban = initialMainPageState.optionTanaban;
      }
      if (state.optionInputStatus === undefined) {
        state.optionInputStatus = initialMainPageState.optionInputStatus;
      }
      if (state.optionOrigins === undefined) {
        state.optionOrigins = initialMainPageState.optionOrigins;
      }
      if (state.optionReasons === undefined) {
        state.optionReasons = initialMainPageState.optionReasons;
      }
      if (state.favoriteOptions === undefined) {
        state.favoriteOptions = initialMainPageState.favoriteOptions;
      }
      if (state.favoriteDisplaySettings === undefined) {
        state.favoriteDisplaySettings = initialMainPageState.favoriteDisplaySettings;
      }
      if (state.showSubTotal === undefined || typeof state.showSubTotal === 'boolean') {
        state.showSubTotal = initialState.showSubTotal;
      }

      //初期表示列0配列は、常に全項目表示とする
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if (!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
      //強制選択列
      state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
      //非表示列
      state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

      if (state.colWidthsModified === undefined) {
        state.colWidthsModified = initialState.colWidthsModified;
      }
      if (state.sort === undefined) {
        state.sort = initialState.sort;
      }
    },
    //componentWillUnmount
    resetOnWillUnmount(state: MainPageSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
    },
    clearOption(state: MainPageSaveState, action: PayloadAction<string>) {
      const key = action.payload;
      // console.log(`clearOption ${key}`);
      if (!key) {
        state.optionGroup1s = initialMainPageState.optionGroup1s;
        state.optionTokuisaki1s = initialMainPageState.optionTokuisaki1s;
        state.optionTokuisaki2s = initialMainPageState.optionTokuisaki2s;
        state.optionInputStatus = initialMainPageState.optionInputStatus;
        state.optionOrigins = initialMainPageState.optionOrigins;
        state.optionReasons = initialMainPageState.optionReasons;
      }
      if (!key || key === 'item') {
        state.optionMakers = initialMainPageState.optionMakers;
        state.optionMakerCode = initialMainPageState.optionMakerCode;
        state.optionItemCode = initialMainPageState.optionItemCode;
      }
      if (!key || key === 'center') {
        state.optionBumons = initialMainPageState.optionBumons;
        state.optionCenters = initialMainPageState.optionCenters;
        state.optionCenterCode = initialMainPageState.optionCenterCode;
      }
      if (!key || key === 'group') {
        state.optionGroup1s = initialMainPageState.optionGroup1s;
        state.optionTokuisaki1s = initialMainPageState.optionTokuisaki1s;
        state.optionTokuisaki2s = initialMainPageState.optionTokuisaki2s;
        state.optionTorihikisakiCode = initialMainPageState.optionTorihikisakiCode;
        state.optionTokuisaki1Code = initialMainPageState.optionTokuisaki1Code;
      }
      if (!key || key === 'slip') {
        state.optionSlipNo = initialMainPageState.optionSlipNo;
        state.optionEosFlg = initialMainPageState.optionEosFlg;
        state.optionSyukkaTerm = initialMainPageState.optionSyukkaTerm;
        state.optionNouhinTerm = initialMainPageState.optionNouhinTerm;
      }
      if (!key || key === 'tanaban') {
        state.optionTanaban = initialMainPageState.optionTanaban;
      }
    },
    setOptionBumons(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionBumons = action.payload;
    },
    setOptionCenters(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionCenters = action.payload;
    },
    setOptionCenterCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionCenterCode = action.payload;
    },
    setOptionMakers(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionMakers = action.payload;
    },
    setOptionMakerCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionMakerCode = action.payload;
    },
    setOptionTorihikisakiCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionTorihikisakiCode = action.payload;
    },
    setOptionTokuisaki1Code(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionTokuisaki1Code = action.payload;
    },
    setOptionItemCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionItemCode = action.payload;
    },
    setOptionSlipNo(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionSlipNo = action.payload;
    },
    setOptionEosFlg(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionEosFlg = action.payload;
    },
    setOptionSyukkaTerm(state: MainPageSaveState, action: PayloadAction<OptionTerm>) {
      state.optionSyukkaTerm = action.payload;
    },
    setOptionNouhinTerm(state: MainPageSaveState, action: PayloadAction<OptionTerm>) {
      state.optionNouhinTerm = action.payload;
    },
    setOptionTanaban(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionTanaban = action.payload;
    },
    setOptionInputStatus(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionInputStatus = action.payload;
    },
    setOptionOrigins(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionOrigins = action.payload;
    },
    setOptionReasons(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionReasons = action.payload;
    },
    setOptionGroup1s(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionGroup1s = action.payload;
    },
    setOptionTokuisaki1s(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionTokuisaki1s = action.payload;
    },
    setOptionTokuisaki2s(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionTokuisaki2s = action.payload;
    },
    setFavoriteOptions(state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) {
      state.favoriteOptions = action.payload;
    },
    setFavoriteDisplaySettings(state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
      state.favoriteDisplaySettings = action.payload;
    },
    setShowSubTotal(state: MainPageSaveState, action: PayloadAction<string>) {
      switch (action.payload) {
        case 'なし':
          state.showSubTotal = 'なし';
          break;
        case 'あり':
          state.showSubTotal = 'あり';
          break;
        case 'のみ':
          state.showSubTotal = 'のみ';
          break;
      }
    },
    columnWidthChange(state: MainPageSaveState, action: PayloadAction<{ colKey: string, width: number }>) {
      console.log('store.columnWidthChange');
      const colKey = action.payload.colKey;
      const width = action.payload.width;
      if (colKey && width) {
        state.colWidthsModified[colKey] = width;
      }
    },
    hideColumnKeysChange(state: MainPageSaveState, action: PayloadAction<string[]>) {
      console.log('store.hideColumnKeysChange');
      //store更新
      state.hiddenColumns = colRowModel.colFromKeys(action.payload);
      state.hiddenColumnsKey = action.payload;
      state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
    },
    setSort(state: MainPageSaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('zaiko setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
  }
};

const createSliceContent = (name: string, colRowModel: MainPageColRowModel, initialState: MainPageSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//mainPageSaveSlice
export const mainPageSaveSlice = createSliceContent("mainPageSave", mainPageColRowModelDefault, initialMainPageState);
