import { CodeName } from "@/assets/commontype/CodeName";

export const compareAny = (v1:any, v2:any, asc:boolean, emptyIsLast?:boolean) => {
  if(typeof v1 == 'number' || typeof v2 == 'number') {
    return compareNumber(v1, v2, asc, emptyIsLast);
  }
  if(typeof v1 == 'string' || typeof v2 == 'string') {
    return compareString(v1, v2, asc, emptyIsLast);
  }
  if(typeof v1 == 'boolean' || typeof v2 == 'boolean') {
    return compareBoolean(v1, v2, asc);
  }
  if(typeof v1 == 'object' || typeof v2 == 'object') {
    if(v1 instanceof Date || v2 instanceof Date) {
      return compareDate(v1, v2, asc, emptyIsLast);
    }
    if(v1 instanceof CodeName || v2 instanceof CodeName) {
      return compareCodeName(v1, v2, asc, emptyIsLast);
    }
  }
  return 0;
}

export const compareNumber = (v1:number, v2:number, asc:boolean, emptyIsLast?:boolean) => {
  const b1 = v1 === undefined || v1 === null;
  const b2 = v2 === undefined || v2 === null;
  if(b1 && b2) return 0;
  if(emptyIsLast) {
    //nullは常に最下層
    if(b1) return 1;
    if(b2) return -1;
  }
  if(b1) return -1 * (asc ? 1 : -1);
  if(b2) return 1 * (asc ? 1 : -1);
  return (v1 === v2 ? 0 : v1 < v2 ? -1 : 1) * (asc ? 1 : -1);
}
export const compareString = (v1:string, v2:string, asc:boolean, emptyIsLast?:boolean) => {
  const b1 = v1 === undefined || v1 === null;
  const b2 = v2 === undefined || v2 === null;
  if(b1 && b2) return 0;
  if(emptyIsLast) {
    //空白は常に最下層
    const e1 = !v1;
    const e2 = !v2;
    if(e1 && e2) return 0;
    if(e1) return 1;
    if(e2) return -1;
  }
  if(b1) return -1 * (asc ? 1 : -1);
  if(b2) return 1 * (asc ? 1 : -1);
  return v1.localeCompare(v2) * (asc ? 1 : -1);
}
export const compareBoolean = (v1:boolean, v2:boolean, asc:boolean) => {
  const b1 = !(!v1);
  const b2 = !(!v2);
  return (b1 === b2 ? 0 : !b1 ? -1 : 1) * (asc ? 1 : -1);
}
export const compareDate = (v1:Date, v2:Date, asc:boolean, emptyIsLast?:boolean) => {
  const b1 = v1 === undefined || v1 === null || Number.isNaN(v1.getDate());
  const b2 = v2 === undefined || v2 === null || Number.isNaN(v2.getDate());
  if(b1 && b2) return 0;
  if(emptyIsLast) {
    //nullは常に最下層
    if(b1) return 1;
    if(b2) return -1;
  }
  if(b1) return 1 * (asc ? 1 : -1);
  if(b2) return -1 * (asc ? 1 : -1);

  const t1 = v1.getTime();
  const t2 = v2.getTime();

  return (t1 > t2 ? 1 : t1 < t2 ? -1 : 0) * (asc ? 1 : -1);
}
export const compareCodeName = (v1:CodeName, v2:CodeName, asc:boolean, emptyIsLast?:boolean) => {
  const b1 = v1 === undefined || v1 === null || !v1.code;
  const b2 = v2 === undefined || v2 === null || !v2.code;
  if(b1 && b2) return 0;
  if(emptyIsLast) {
    //nullは常に最下層
    if(b1) return 1;
    if(b2) return -1;
  }
  if(b1) return 1 * (asc ? 1 : -1);
  if(b2) return -1 * (asc ? 1 : -1);

  const t1 = v1.code;
  const t2 = v2.code;

  return (t1 > t2 ? 1 : t1 < t2 ? -1 : 0) * (asc ? 1 : -1);
}
