import {
  combineReducers
} from "@reduxjs/toolkit";

import {
  createStore,
  applyMiddleware,
  compose,
} from "redux";

import {
  persistReducer
} from "redux-persist";

// import storage from "redux-persist/lib/storage";
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

// custom stores
import { userSlice } from "@/store/user";
import { userSaveSlice } from "@/store/userSave";
import { mainPageSaveSlice } from "@/store/mainpage/mainPageSave";
import { mainPageTmpSlice } from "@/store/mainpage/mainPageTmp";

const rootReducer = combineReducers({
  user: userSlice.reducer,
  userSave: userSaveSlice.reducer,
  mainPageSave: mainPageSaveSlice.reducer,
  mainPageTmp: mainPageTmpSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/*永続化保存設定 */

// // HACK: `redux-persist failed to create sync storage. falling back to noop storage.`の対応
// // https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem() {
      return Promise.resolve()
    },
  }
}
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage()
;
//通常時
const persistConfigDefault = {
  //localstorage key
  key: process.env.NEXT_PUBLIC_STORAGE_KEY,
  version: 1,
  //localstorage
  storage,
  //保存対象
  whitelist: [
    userSaveSlice.name,
    mainPageSaveSlice.name,
  ],
  timeout: 500, //500msec
};
//URL共有用
const persistConfigShare = {
  //localstorage key
  key: process.env.NEXT_PUBLIC_STORAGE_KEY + '-share',
  version: 1,
  //localstorage
  storage,
  //保存対象
  whitelist: [
  ],
  timeout: 500, //500msec
};


interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;
const composeReduxDevToolsEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [];
const makeStoreInner = (share: boolean) => {
  const persistedReducer = persistReducer(share ? persistConfigShare : persistConfigDefault, rootReducer);

  return createStore(persistedReducer,composeReduxDevToolsEnhancers(applyMiddleware(...middlewares)));
};

// Reduxのstoreはsingletonが推奨されている
// https://redux.js.org/faq/store-setup#store-setup
// https://qiita.com/nouka/items/74a2784598e7bd5554cd#%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6-1-%E3%81%A4%E3%81%AE-redux-store-%E3%82%92%E6%8C%81%E3%81%A4%E3%81%93%E3%81%A8
// shareフラグが同一であれば、以前作成したStoreを返す。
let storeCache = null as null | { share: boolean, store: ReturnType<typeof makeStoreInner> };
export const makeStore = (share: boolean) => {
  if (storeCache !== null && storeCache.share === share) {
    return storeCache.store;
  }
  const store = makeStoreInner(share);
  storeCache = {
    share,
    store,
  }
  return store;
}
