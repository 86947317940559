import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import { OptionTerm } from "@/components/custom/TermOptionSelecter";

export const centersNameList = [
  'optionBumons',
  'optionCenters',
  'optionCenterCode',
];
export type CenterOptions = {
  optionBumons?: CodeName[],      //部門
  optionCenters?: CodeName[],     //倉庫
  optionCenterCode?: string,      //倉庫CD
};

export const itemsNameList = [
  'optionMakers',
  'optionMakerCode',
  'optionItemCode',
];
export type ItemOptions = {
  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,     //メーカーCD
  optionItemCode?: string,      //商品CD
};

export const groupsNameList = [
  'optionGroup1s',
  'optionTokuisaki1s',
  'optionTokuisaki2s',
  'optionTorihikisakiCode',
  'optionTokuisaki1Code',
];
export type GroupOptions = {
  optionGroup1s?: CodeName[],      //企業グループ1
  optionTokuisaki1s?: CodeName[],  //得意先本部
  optionTokuisaki2s?: CodeName[],  //得意先支店
  optionTorihikisakiCode?: string,     //取引先CD
  optionTokuisaki1Code?: string,     //得意先本部CD
};

export const slipsNameList = [
  'optionSlipNo',
  'optionEosFlg',
  'optionSyukkaTerm',
  'optionNouhinTerm',
];
export type SlipOptions = {
  optionSlipNo?: string,                  //伝票番号
  optionEosFlg?: '' | '1' | '0' | string, //EOSフラグ   1:EOS  0:手打ち
  optionSyukkaTerm?: OptionTerm | null,   //出荷日
  optionNouhinTerm?: OptionTerm | null,   //納品日
};

export const tanabansNameList = [
  'optionTanaban',
];
export type TanabanOptions = {
  optionTanaban?: string,                   //棚番
};

export type UserSaveState = {
  url: string,
  favoriteCenters: { title: CodeName, params: CenterOptions }[] | null,
  favoriteItems: { title: CodeName, params: ItemOptions }[] | null,
  favoriteGroups: { title: CodeName, params: GroupOptions }[] | null,
  favoriteSlips: { title: CodeName, params: SlipOptions }[] | null,
  favoriteTanabans: { title: CodeName, params: TanabanOptions }[] | null,
};

const initialState: UserSaveState = {
  url: null,
  favoriteCenters: [],
  favoriteItems: [],
  favoriteGroups: [],
  favoriteSlips: [],
  favoriteTanabans: [],
};

//Page Slice
export type UserSaveReducer = {
  initOnDidMount: (state: UserSaveState) => void,
  resetOnWillUnmount: (state: UserSaveState) => void,
  setUrl: (state, action: PayloadAction<string>) => void,

  setFavoriteCenters: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: CenterOptions }[]>) => void,
  setFavoriteItems: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: ItemOptions }[]>) => void,
  setFavoriteGroups: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: GroupOptions }[]>) => void,
  setFavoriteSlips: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: SlipOptions }[]>) => void,
  setFavoriteTanabans: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: TanabanOptions }[]>) => void,
}

const createReducerContent = (name: string, initialState: UserSaveState): UserSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: UserSaveState) {
      if (state.favoriteCenters === undefined) {
        state.favoriteCenters = initialState.favoriteCenters;
      }
      if (state.favoriteItems === undefined) {
        state.favoriteItems = initialState.favoriteItems;
      }
      if (state.favoriteGroups === undefined) {
        state.favoriteGroups = initialState.favoriteGroups;
      }
      if (state.favoriteSlips === undefined) {
        state.favoriteSlips = initialState.favoriteSlips;
      }
      if (state.favoriteTanabans === undefined) {
        state.favoriteTanabans = initialState.favoriteTanabans;
      }
    },
    //componentWillUnmount
    resetOnWillUnmount(state: UserSaveState) {
    },
    setUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
    setFavoriteCenters(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: CenterOptions }[]>) {
      state.favoriteCenters = action.payload;
    },
    setFavoriteItems(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: ItemOptions }[]>) {
      state.favoriteItems = action.payload;
    },
    setFavoriteGroups(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: GroupOptions }[]>) {
      state.favoriteGroups = action.payload;
    },
    setFavoriteSlips(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: SlipOptions }[]>) {
      state.favoriteSlips = action.payload;
    },
    setFavoriteTanabans(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: TanabanOptions }[]>) {
      state.favoriteTanabans = action.payload;
    },
  }
};

const createSliceContent = (name: string, initialState: UserSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

export const userSaveSlice = createSliceContent("userSave", initialState);