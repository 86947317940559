import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserState = {
  isAuthed: boolean,
  company_id: string,
  company: string,
  update_datetime: string,
  user_id: string,
  name: string,
  permissions: string[],
  token: string,
  error?: {message:string},
};

const initialState: UserState = {
  isAuthed: false,
  company_id: "",
  company: "",
  update_datetime: "",
  user_id: "",
  name: "",
  permissions: [],
  token: "",
  error: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // HACK: reducerは肥大化したらファイル分けたくなるかも
  reducers: {
    //componentDidMount
    initOnDidMount(state) {
    },
    //componentWillUnmount
    resetOnWillUnmount() {
      //pass
    },
    success(state) {
      state.isAuthed = true;
    },
    setUserState(state, action: PayloadAction<UserState>) {
      Object.assign(state, action.payload);
    },
    error(state, action: PayloadAction<{message:string}>) {
      state.isAuthed = false;
      state.error = action.payload;
    },
    clearUserState(state) {
      Object.assign(state, initialState);
    },
  },
});